import * as React from 'react'
import { createContext, useContext, useReducer, useState } from 'react'
import { PersonaPresence } from 'office-ui-fabric-react'
import getProfilePicWithOptimize from '../helpers/ProfilePicOptimizeHelper'
import UserStore from '../stores/userStore'
import axios from 'axios'
import { fetchPresence } from '../helpers/GraphHelpers'
import { Moment } from 'moment'
import * as moment from 'moment'
import { startIndex } from '../helpers/GroupingHelpers'

const idMap = new Map()

export const MeetingContext = createContext({
  fetchMeetings: async ({
    showMore = false,
    renewMeetings = true,
    weekStartDate = moment().startOf('isoWeek'),
    weekEndDate = moment().startOf('isoWeek').add(4, 'days'),
    showMoreMode = 'upcoming',
    isModeSelectActive = false
  }: {
    showMore?: boolean
    renewMeetings?: boolean
    weekStartDate?: Moment
    weekEndDate?: Moment
    showMoreMode?: 'past' | 'upcoming'
    isModeSelectActive?: boolean
  }) => {},
  getMeetings: ({
    weekStartDate = moment().startOf('isoWeek'),
    weekEndDate = moment().startOf('isoWeek').add(4, 'days'),
    isRefresh = false,
    filterOneOnOne = false
  }: {
    weekStartDate?: Moment
    weekEndDate?: Moment
    isRefresh?: boolean
    filterOneOnOne?: boolean
  }) => {
    return {
      meetings: [],
      upcomingMeetings: [],
      previousMeetings: [],
      upcomingGroupedMeetings: [],
      previousGroupedMeetings: [],
      groupedMeetings: []
    }
  },
  getUpcomingMeetings: (): any => {},
  getIsLoading: (): boolean => {
    return true
  },
  getIsModuleActive: (): boolean => {
    return true
  },
  getGraphError: (): boolean => {
    return true
  },
  getGraphErrorText: (): string => {
    return ''
  },
  getIsInitialized: (): boolean => {
    return true
  },
  resetMeetings: () => {},
  getMeetingFilterSettings: () => {
    return {
      enableMeetingKeyword: false,
      meetingFilterKeywords: []
    }
  },
  removeMeetingInstance: meetingId => {},
  removeMeetingOccurrence: seriesMasterId => {},
  state: {
    meetings: [],
    isLoading: false,
    limitStartDate: moment().startOf('isoWeek'),
    limitEndDate: moment().startOf('isoWeek').add(4, 'days'),
    enableMeetingKeyword: false,
    meetingFilterKeywords: [],
    upcomingMeetings: [],
    previousMeetings: [],
    groupedMeetings: [],
    upcomingGroupedMeetings: [],
    previousGroupedMeetings: [],
    graphError: false,
    graphErrorText: '',
    isModuleActive: true,
    isInitialized: false
  }
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-meetings': {
      const newState = { ...state }
      newState.meetings = action.meetings

      let upcomingMeetings = newState.meetings.filter(event =>
        moment(event.startDateTime).startOf('day').isSameOrAfter(moment().startOf('day'))
      )
      let previousMeetings = newState.meetings
        .filter(event => moment(event.startDateTime).startOf('day').isSameOrBefore(moment().startOf('day')))
        .sort((a, b) => moment(b.startDateTime).diff(moment(a.startDateTime)))

      newState.upcomingMeetings = upcomingMeetings
      newState.previousMeetings = previousMeetings

      newState.groupedMeetings = groupByDate(newState.meetings)
      newState.upcomingGroupedMeetings = groupByDate(upcomingMeetings)
      newState.previousGroupedMeetings = groupByDate(previousMeetings)

      return newState
    }
    case 'update-meetings': {
      const newState = { ...state }
      newState.meetings = newState.meetings
        .concat(action.meetings)
        .filter((currentValue, index, arr) => arr.findIndex(e => e.meetingId === currentValue.meetingId) === index)

      let upcomingMeetings = newState.meetings.filter(event =>
        moment(event.startDateTime).startOf('day').isSameOrAfter(moment().startOf('day'))
      )
      let previousMeetings = newState.meetings
        .filter(event => moment(event.startDateTime).startOf('day').isSameOrBefore(moment().startOf('day')))
        .sort((a, b) => moment(b.startDateTime).diff(moment(a.startDateTime)))

      newState.upcomingMeetings = upcomingMeetings
      newState.previousMeetings = previousMeetings

      newState.groupedMeetings = groupByDate(newState.meetings)
      newState.upcomingGroupedMeetings = groupByDate(upcomingMeetings)
      newState.previousGroupedMeetings = groupByDate(previousMeetings)
      return newState
    }
    case 'set-loading': {
      const newState = { ...state }
      newState.isLoading = action.loading
      return newState
    }
    case 'set-limit-dates': {
      const newState = { ...state }
      newState.limitStartDate = action.limitStartDate
      newState.limitEndDate = action.limitEndDate
      return newState
    }
    case 'set-meeting-keywords': {
      const newState = { ...state }
      newState.enableMeetingKeyword = action.enableMeetingKeyword
      newState.meetingFilterKeywords = action.meetingFilterKeywords
      return newState
    }
    case 'set-upcoming-meetings': {
      const newState = { ...state }
      newState.upcomingMeetings = action.upcomingMeetings
      newState.upcomingGroupedMeetings = groupByDate(action.upcomingMeetings)
      return newState
    }
    case 'set-previous-meetings': {
      const newState = { ...state }
      newState.previousMeetings = action.previousMeetings
      newState.previousGroupedMeetings = groupByDate(action.previousMeetings)
      return newState
    }
    case 'set-graph-error': {
      const newState = { ...state }
      newState.graphError = action.graphError
      newState.graphErrorText = action.graphErrorText
      newState.isLoading = action.isLoading
      return newState
    }
    case 'set-is-module-active': {
      const newState = { ...state }
      newState.isModuleActive = action.isModuleActive
      return newState
    }
    case 'set-is-initialized': {
      const newState = { ...state }
      newState.isInitialized = action.isInitialized
      return newState
    }
    case 'reset-state': {
      return action.initialState
    }
  }
}

const staticRequestLoadingState = {
  meetings: {}
}

const MeetingContextProvider = props => {
  // #region Reducer =================================================================
  const [state, dispatch] = useReducer(reducer, {
    meetings: [],
    isLoading: false,
    limitStartDate: moment().startOf('isoWeek'),
    limitEndDate: moment().startOf('isoWeek').add(4, 'days'),
    enableMeetingKeyword: false,
    meetingFilterKeywords: [],
    upcomingMeetings: [],
    previousMeetings: [],
    groupedMeetings: [],
    upcomingGroupedMeetings: [],
    previousGroupedMeetings: [],
    graphError: false,
    graphErrorText: '',
    isModuleActive: true,
    isInitialized: false
  })

  const resetMeetings = () => {
    dispatch({
      type: 'reset-state',
      initialState: {
        meetings: [],
        isLoading: false,
        limitStartDate: moment().startOf('isoWeek'),
        limitEndDate: moment().startOf('isoWeek').add(4, 'days'),
        enableMeetingKeyword: false,
        meetingFilterKeywords: [],
        upcomingMeetings: [],
        previousMeetings: [],
        groupedMeetings: [],
        upcomingGroupedMeetings: [],
        previousGroupedMeetings: [],
        graphError: false,
        graphErrorText: '',
        isModuleActive: true,
        isInitialized: false
      }
    })
    setTimeout(() => {
      fetchMeetings({ renewMeetings: true })
    }, 0)
  }
  // #endregion

  // #region Mounting Functions ==============================================================
  const fetchMeetings = async ({
    showMore = false,
    renewMeetings = true,
    weekStartDate = moment().startOf('isoWeek'),
    weekEndDate = moment().startOf('isoWeek').add(4, 'days'),
    showMoreMode = 'upcoming',
    isModeSelectActive = false
  }: {
    showMore?: boolean
    renewMeetings?: boolean
    weekStartDate?: Moment
    weekEndDate?: Moment
    showMoreMode?: 'past' | 'upcoming'
    isModeSelectActive?: boolean
  }) => {
    let localStartDate = moment(weekStartDate)
    let localEndDate = moment(weekEndDate)
    dispatch({ type: 'set-loading', loading: true })

    if (showMore) {
      if (showMoreMode === 'past') {
        localStartDate = moment(state.limitStartDate).subtract(7, 'days')
        localEndDate = moment(state.limitEndDate).subtract(7, 'days')
        dispatch({ type: 'set-limit-dates', limitStartDate: localStartDate, limitEndDate: localEndDate })
      } else {
        localStartDate = moment(state.limitStartDate).add(7, 'days')
        localEndDate = moment(state.limitEndDate).add(7, 'days')
        dispatch({ type: 'set-limit-dates', limitStartDate: localStartDate, limitEndDate: localEndDate })
      }
    }

    axios
      .post('/api/oneonone/getAllMeetings', {
        mode: showMoreMode,
        timezoneOffset: moment().utcOffset(),
        startDate: localStartDate.format('YYYY-MM-DD'),
        endDate: localEndDate.format('YYYY-MM-DD'),
        isModeSelectActive: isModeSelectActive
      })
      .then(response => {
        dispatch({
          type: 'set-meeting-keywords',
          enableMeetingKeyword: response.data.enableMeetingKeyword ?? false,
          meetingFilterKeywords: response.data.meetingFilterKeywords ?? []
        })

        let events = response.data.events
        let processedEvents = state.meetings

        if (renewMeetings) {
          processedEvents = events
          dispatch({ type: 'set-meetings', meetings: processedEvents })
        } else {
          processedEvents = state.meetings
            .concat(events)
            .filter((currentValue, index, arr) => arr.findIndex(e => e.meetingId === currentValue.meetingId) === index)

          dispatch({ type: 'update-meetings', meetings: processedEvents })
        }

        dispatch({ type: 'set-loading', loading: false })
        dispatch({ type: 'set-is-initialized', isInitialized: true })
      })
      .catch(error => {
        console.log('err : ', error)
        const errorData = error?.response?.data
        const errorBody = errorData?.body ? JSON.parse(errorData.body) : null
        const errorCode = errorBody?.code || errorData?.code
        const errorMessage = errorBody?.message || errorData?.message

        if (error.response?.status === 500) {
          if (errorCode === 'MailboxNotEnabledForRESTAPI') {
            dispatch({
              type: 'set-graph-error',
              graphError: true,
              graphErrorText: errorMessage,
              isLoading: false
            })
          } else {
            dispatch({
              type: 'set-graph-error',
              graphError: false,
              graphErrorText: '',
              isLoading: false
            })
          }
        } else if (error?.response?.status === 401) {
          // Disabled from tenant
          dispatch({ type: 'set-is-module-active', isModuleActive: false })
        } else {
          // Try again after 3 seconds
          setTimeout(() => {
            fetchMeetings({ weekStartDate: localStartDate, weekEndDate: localEndDate, renewMeetings: false })
          }, 3000)
        }
      })
  }

  //#endregion

  // #region Getter Functions ================================================================
  const getMeetings = ({
    weekStartDate = moment().startOf('isoWeek'),
    weekEndDate = moment().startOf('isoWeek').add(4, 'days'),
    isRefresh = false,
    filterOneOnOne = false
  }: {
    weekStartDate: Moment
    weekEndDate: Moment
    isRefresh: boolean
    filterOneOnOne: boolean
  }) => {
    let limitStartDate = state.limitStartDate
    let limitEndDate = state.limitEndDate

    if (moment(weekStartDate).isBefore(limitStartDate)) {
      dispatch({ type: 'set-limit-dates', limitStartDate: weekStartDate, limitEndDate: limitEndDate })
      fetchMeetings({ weekStartDate: weekStartDate, weekEndDate: limitStartDate, renewMeetings: false })
    }
    if (moment(weekEndDate).isAfter(limitEndDate)) {
      dispatch({
        type: 'set-limit-dates',
        limitStartDate: limitStartDate,
        limitEndDate: weekEndDate,
        renewMeeting: false
      })
      fetchMeetings({ weekStartDate: limitEndDate, weekEndDate: weekEndDate, renewMeetings: false })
    }

    // Make sure upcoming meetings are always up to date
    if (isRefresh) {
      if (state.isInitialized) {
        fetchMeetings({ weekStartDate: weekStartDate, weekEndDate: weekEndDate, renewMeetings: false })
      }
    }

    // TODO: Implement filterOneOnOne

    return {
      meetings: state.meetings,
      groupedMeetings: state.groupedMeetings,
      upcomingMeetings: state.upcomingMeetings,
      previousMeetings: state.previousMeetings,
      upcomingGroupedMeetings: state.upcomingGroupedMeetings,
      previousGroupedMeetings: state.previousGroupedMeetings
    }
  }

  const getUpcomingMeetings = () => {
    fetchMeetings({ weekStartDate: moment(), weekEndDate: moment(), renewMeetings: true, isModeSelectActive: true })
    return state.upcomingMeetings
  }

  const getIsLoading = () => {
    return state.isLoading
  }

  const getIsModuleActive = () => {
    return state.isModuleActive
  }

  const getGraphError = () => {
    return state.graphError
  }

  const getGraphErrorText = () => {
    return state.graphErrorText
  }

  const getIsInitialized = () => {
    return state.isInitialized
  }

  const getMeetingFilterSettings = () => {
    return {
      enableMeetingKeyword: state.enableMeetingKeyword,
      meetingFilterKeywords: state.meetingFilterKeywords
    }
  }

  // #endregion

  // #region Setter Functions ================================================================
  const removeMeetingInstance = meetingId => {
    let meetings = state.meetings.filter(m => m.meetingId !== meetingId)
    dispatch({ type: 'set-meetings', meetings: meetings })
  }

  const removeMeetingOccurrence = seriesMasterId => {
    let meetings = state.meetings.filter(m => m.seriesMasterId !== seriesMasterId)
    dispatch({ type: 'set-meetings', meetings: meetings })
  }
  // #endregion

  return (
    <MeetingContext.Provider
      value={{
        fetchMeetings,
        getMeetings,
        getUpcomingMeetings,
        getIsLoading,
        getIsModuleActive,
        getGraphError,
        getGraphErrorText,
        getIsInitialized,
        resetMeetings,
        getMeetingFilterSettings,
        removeMeetingInstance,
        removeMeetingOccurrence,
        state
      }}
    >
      {props.children}
    </MeetingContext.Provider>
  )
}

//#region Helper Functions =================================================================
const groupByDate = meetings => {
  let groups = []
  let obj: any
  meetings.forEach((meeting, i) => {
    let date = meeting.startStr
    if (!groups.find(g => g.name === date)) {
      obj = {
        key: `${date},${i}`,
        name: date,
        count: 0,
        data: { fullCount: 0 },
        hasMoreData: false,
        startIndex: startIndex(groups)
      }
      meetings.forEach(mt => {
        let date1 = mt.startStr
        if (date === date1) {
          obj.count++
          obj.data.fullCount++
        }
      })
      groups.push(obj)
    }
  })

  return groups
}

//#endregion

const useMeeting = () => {
  return useContext(MeetingContext)
}

export { MeetingContextProvider, useMeeting }
