import axios from 'axios'
import i18n from 'i18next'
import * as moment from 'moment'

const setLanguage = (newLanguage: string) => {
  axios.defaults.headers.language = newLanguage
  i18n.changeLanguage(newLanguage)
  moment.locale(newLanguage)
}

const language = () => {
  return i18n.language
}

const allLanguages = (async () => await i18n.loadLanguages(['en', 'de', 'fr', 'es']))()

const getKeyOfAllLanguages = (key: string) => {
  const valueOfKey = i18n.getFixedT(language())(key)
  const enLang = i18n.getFixedT('en')
  const deLang = i18n.getFixedT('de')
  const frLang = i18n.getFixedT('fr')
  const esLang = i18n.getFixedT('es')
  const prevValueArr = [enLang(key), deLang(key), frLang(key), esLang(key)]

  return { valueOfKey, prevValueArr }
}

export { setLanguage, getKeyOfAllLanguages }
